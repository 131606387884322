export const topics = [
    {
        id: 0,
        name: 'Automated testing',
        link: '/qa'
    },
    {
        id: 1,
        name: 'Proactive monitoring',
        link: '/proactive-monitoring'
    },
    {
        id: 2,
        name: 'Benchmarking',
        link: '/benchmarking'
    },
    {
        id: 3,
        name: 'Press release',
        link: '/press-release'
    }
];