/* eslint-disable */
import React, { useState } from 'react';

import BlogArticleCard from '../blog/BlogArticleCard';

import articlesStyles from '../../pages/styles/articles.module.scss';
import styles from './styles/resourcesArticles.module.scss';

export default function FilteredResourcesMobile({ topics, industries, data }) {
    const [count, setCount] = useState(10);

    const loadMore = () => {
        setCount(count + 3);
    }

    const articlesToDisplay = data.filter(article =>
        topics.some(item => article.topics?.includes(item)) || industries.some(item => article.industries?.includes(item))
    );

    const allFilters = [...topics, ...industries];

    const titleStyle = allFilters.length === 0 ? articlesStyles.noshow : articlesStyles.show;

    return (
        <>
            <div className={titleStyle}>Filtered</div>
            <section className={styles.articlesContainer}>
                {articlesToDisplay.slice(0, count).map(article => <BlogArticleCard article={article} key={article.postId} />)}

                { articlesToDisplay.length > count ?
                    <button
                        onClick={loadMore}
                        className={articlesStyles.blueButton}
                    >
                        More articles
                    </button>
                :null
                }

            </section>
        </>
    );
}
