/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';
import Modal from '@witbe/ui-react-components/dist/Modal';
import { topics } from '../../utils/topics';
import { industries } from '../../utils/industries';
import cssResources from '../../pages/styles/resources.module.scss';
import FilterShowAllMobile from './FilterShowAllMobile';
import SearchListNoResults from './SearchListNoResults';
import SearchListMobile from './SearchListMobile';
import { DropdownModalContext, DropdownIndustryModalContext } from '../../context';
import { useArticles, useExternalArticles, useViewport } from '../../hooks';
import { stripHtmlTags } from '../../utils';
import mobileStyles from './styles/mobileStyles.module.scss';
import articlesStyles from '../../pages/styles/articles.module.scss';
import homeStyles from '../../pages/styles/newHome.module.scss';
import resourcesArticles from './styles/resourcesArticles.module.scss';
import Dropdown from './Dropdown';
import FilteredResourcesMobile from './FilteredResourcesMobile';
import dropdownStyles from './styles/dropdown.module.scss';
import ExternalArticlesMobile from './ExternalArticlesMobile';

export default function MobileResources({ topicData, industryData, searchProp }) {
    const { t } = useTranslation();

    console.log('search data:', searchProp);

    const { width } = useViewport();
    const breakpoint = 800;

    const myArray = Array.from({ length: topics.length }).fill(false);
    const currentTopic = topics.find(element => element.name === topicData);
    const slicedArray = topicData ? myArray.toSpliced(currentTopic.id, 1, true) : null;

    const myArrayIndustry = Array.from({ length: industries.length }).fill(false);
    const currentIndustry = industries.find(element => element.name === industryData);
    const slicedArrayIndustry = industryData ? myArrayIndustry.toSpliced(currentIndustry.id - 4, 1, true) : null;

    const [ filteredData, setFilteredData ] = useState([]);
    const [ filteredIndustryData, setFilteredIndustryData ] = useState([]);

    //Dropdown Modal - TOPIC
    const [ toggleDropdownModal, setToggleDropdownModal ] = useContext(DropdownModalContext);
    const [ dropdownSubmitted, setDropdownSubmitted ] = useState(false);

    const myDropdownToggle = (topicTotal, industryTotal) => {
        // boot();
        setToggleDropdownModal(!toggleDropdownModal);
        setDropdownSubmitted(false);
        setFilteredIndustryData([]);

        topicTotal ? setFilteredData(topicTotal)
            : null;

        industryTotal ? setFilteredIndustryData(industryTotal)
            : null;
    };

    //Dropdown Modal - INDUSTRY
    const [ toggleDropdownIndustryModal, setToggleDropdownIndustryModal ] = useContext(DropdownIndustryModalContext);
    const [ dropdownIndustrySubmitted, setDropdownIndustrySubmitted ] = useState(false);

    const myDropdownIndustryToggle = childdata => {
        setToggleDropdownIndustryModal(!toggleDropdownIndustryModal);
        setDropdownIndustrySubmitted(false);
        setFilteredData([]);

        childdata ? setFilteredIndustryData(childdata)
            : null;
    };

    //
    const [ isOpen, setIsOpen ] = useState(false);
    const toggling = () => setIsOpen(!isOpen);

    const [ isOpenIndustry, setIsOpenIndustry ] = useState(false);
    const togglingIndustry = () => setIsOpenIndustry(!isOpenIndustry);

    const [ checkedState, setCheckedState ] = useState([]);

    const [ checkedStateIndustry, setCheckedStateIndustry ] = useState([]);

    //Search bar
    const [ searchField, setSearchField ] = useState('');
    const [ searchShow, setSearchShow ] = useState(false);

    //Filtered by Topic and Industry
    const [ total, setTotal ] = useState([]);
    const [ totalIndustry, setTotalIndustry ] = useState([]);

    const articleDataArray = useArticles();

    const [ searchArticles, setSearchArticles ] = useState(articleDataArray);

    const articleData = useExternalArticles();

    const filteredArticles = articleDataArray.filter(
        // eslint-disable-next-line arrow-body-style
        article => {
            const search = searchField.toLowerCase();
            return (
                stripHtmlTags(article.titleList)
                    .toLowerCase()
                    .includes(search)
                || article
                    .subtitle
                    ?.toLowerCase()
                    .includes(search)
                || article
                    .keywordsSearch
                    ?.toLowerCase()
                    .includes(search)
                || article
                    .topics
                    ?.map(word => word.toLowerCase())
                    ?.toString()
                    .includes(search)
                || article
                    .industries
                    ?.map(word => word.toLowerCase())
                    ?.toString()
                    .includes(search)
                || article
                    .tags
                    ?.map(word => word.toLowerCase())
                    ?.toString()
                    .includes(search)
            );
        });

    //Search bar
    const handleChange = e => {
        setSearchField(e.target.value);
        if (e.target.value === '') {
            setSearchShow(false);
        } else {
            setSearchShow(true);
        }
    };

    //Search function
    function searchList() {
        if (searchShow) {
            if (filteredArticles.length > 0) {
                return (
                    <SearchListMobile filteredArticles={filteredArticles} pageNum={page} />
                );
            }
            return (
                <SearchListNoResults />
            );
        }
    }

    const [ page, setPage ] = useState(1);

    useEffect(() => {
        searchShow ? setSearchArticles(filteredArticles)
            : setSearchArticles(articleDataArray);
    }, [ searchField, total, totalIndustry ]);

    useEffect(() => {
        setPage(1);
    }, [ searchField, total, totalIndustry ]);

    useEffect(() => {
        topicData ? setCheckedState(slicedArray) : null;
    }, []);

    // useEffect(() => {
    //     topicData && window ? window.scrollTo({top: 1000, left: 0, behavior: 'smooth'}) : null;
    // }, [ topicData ]);

    useEffect(() => {
        topicData ? setTotal([ topicData ]) : null;
    }, []);

    useEffect(() => {
        industryData ? setCheckedStateIndustry(slicedArrayIndustry) : null;
    }, []);

    // useEffect(() => {
    //     industryData && window ? window.scrollTo({top: 1000, left: 0, behavior: 'smooth'}) : null;
    // }, [ industryData ]);

    useEffect(() => {
        industryData ? setTotalIndustry([ industryData ]) : null;
    }, []);

    useEffect(() => {
        searchProp ? setSearchShow(true) : null;
    }, []);

    // useEffect(() => {
    //     searchProp && window ? window.scrollTo({top: 1000, left: 0, behavior: 'smooth'}) : null;
    // }, [ searchProp ]);

    useEffect(() => {
        searchProp ? setSearchField(searchProp) : null;
    }, []);

    return (
        <>
            <h1 className={articlesStyles.hidden}>Articles</h1>
            <Modal
                visible={toggleDropdownModal}
                onClose={myDropdownToggle}
                style={{ position: 'fixed' }}
                className={dropdownStyles.modalContainer}
            >
                {dropdownSubmitted === false
                    ? (
                        <Dropdown
                            showModal
                            onClose={myDropdownToggle}
                            setSubmitted={setDropdownSubmitted}
                            type='topic'
                        />
                    )
                    : null}
            </Modal>
            <Modal
                visible={toggleDropdownIndustryModal}
                onClose={myDropdownIndustryToggle}
            >
                {dropdownIndustrySubmitted === false
                    ? (
                        <Dropdown
                            showModal
                            onClose={myDropdownIndustryToggle}
                            setSubmitted={setDropdownIndustrySubmitted}
                        />
                    )
                    : null}
            </Modal>
            <section id={articlesStyles.searchSectionMobile}>
                <section id={cssResources.searchBar}>
                    <div id={cssResources.subscribeLabel}>
                        <div className={cssResources.subscribeInput}>
                            {/* <FontAwesomeIcon icon={faSearch} className={cssResources.searchIcon} /> */}
                            <input
                                className={cssResources.subscribeInputField}
                                placeholder={width < breakpoint ? 'Search articles' : 'Search resources'}
                                type='search'
                                value={searchField}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </section>
                <section id={articlesStyles.dropdown}>
                    <div className={mobileStyles.dropdownContainer}>
                        <div className={mobileStyles.dropdownHeaderLeft} onClick={setToggleDropdownModal}>
                            <p>Filters</p>
                        </div>
                    </div>
                </section>
            </section>
            <section id={cssResources.articlesContainer}>
                <div className={cssResources.articlesMain}>
                    {
                        searchShow && filteredData.length === 0 && filteredIndustryData.length === 0
                            ? searchList()
                            : null
                    }
                    {
                        (filteredData !== undefined || filteredData.length > 0) || (filteredIndustryData !== undefined || filteredIndustryData.length > 0) && !searchShow
                            ? <FilteredResourcesMobile search={searchField} searchState={searchShow} topics={filteredData} industries={filteredIndustryData} data={searchArticles} pageNum={page} />
                            : null
                    }
                    {
                        filteredData.length === 0 && filteredIndustryData.length === 0 && !searchShow
                            ? <FilterShowAllMobile data={articleDataArray} pageNum={page} />
                            : null
                    }
                </div>
                <div id={cssResources.articlesMain}>
                    <ExternalArticlesMobile data={articleData} />
                </div>
            </section>
        </>
    );
}
