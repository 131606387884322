export const industries = [
    {
        id: 5,
        name: 'Broadcaster & Media',
        link: '/broadcaster'
    },
    {
        id: 6,
        name: 'Cable Company',
        link: '/Cable Company'
    },
    {
        id: 7,
        name: 'Device Manufacturer',
        link: '/device'
    },
    {
        id: 8,
        name: 'Hospitality',
        link: '/hospitality'
    },
    {
        id: 9,
        name: 'OTT/Video Service Provider',
        link: '/ott'
    },
    {
        id: 10,
        name: 'Telecommunications Operator',
        link: '/Telecommunications Operator'
    }
];