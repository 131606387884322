import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useArticles } from '../../hooks';
import { joinItemsWithBullet } from '../../utils';

import Link from '../common/Link';

import stylesBlogHome from './styles/blogHomeSidebar.module.scss';
import stylesArticle from './styles/blogSidebar.module.scss';

const propTypes = {
    id: PropTypes.string,
    category: PropTypes.string,
    isBlogHome: PropTypes.bool,
};

const defaultProps = {
    id: null,
    category: null,
    isBlogHome: false,
}

// The component displays the last posts by default,
// or other posts from a category if `id` and `category` are given
const BlogSidebarPosts = ({ id, category, isBlogHome }) => {
    const articlesData = useArticles();

    const shouldDisplayFromCategory = id && category;
    const postsToDisplay = shouldDisplayFromCategory
        ? articlesData.filter(article => article.topics?.includes(category) && article.postId !== id).slice(0, 4)
        : articlesData.slice(0, 5)

    const styles = isBlogHome ? stylesBlogHome : stylesArticle;

    return (
        <div className={classNames(styles.listsContainerRecentPosts, isBlogHome && styles.blogHome)}>
            <h3 className={styles.sidebarHeader}>
                {shouldDisplayFromCategory ? `More in ${category}` : 'Recent posts'}
            </h3>
            {
                postsToDisplay.map(article => (
                    <Link
                        to={article.link}
                        key={article.postId}
                        className={styles.sidebarItem}
                    >
                        <h4 className={styles.sidebarItemTitle} dangerouslySetInnerHTML={{ __html: article.titleList }} />
                        <p className={styles.sidebarItemCategory}>{joinItemsWithBullet(article.topics)}</p>
                    </Link>
                ))
            }
        </div>
    );
}

BlogSidebarPosts.propTypes = propTypes;
BlogSidebarPosts.defaultProps = defaultProps;

export default BlogSidebarPosts;
