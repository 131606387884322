/* eslint-disable func-style */
/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';
import { useTranslation } from 'react-i18next';
import resourcesArticles from './styles/resourcesArticles.module.scss';

import homeStyles from '../../pages/styles/newHome.module.scss';
import articlesStyles from '../../pages/styles/articles.module.scss';

export default function ExternalArticlesMobile({ data }) {
    const { t } = useTranslation();

    const [ count, setCount ] = useState(4);

    const loadMore = () => {
        setCount(count + 2);
    }

    const articlesToDisplay = data;

    return (
        <>
            <div className={articlesStyles.featuredExt}>
                <h4>
                    Witbe in the news
                </h4>
            </div>
            <section className={resourcesArticles.articlesContainer}>
                {articlesToDisplay.slice(0, count)
                    .map(article => (
                        <a href={article.link} target='_blank' alt={article.alt} rel='noreferrer' key={article.postId}>
                            <li className={articlesStyles.resourceElementMobileNoBorder}>
                                <GatsbyImage
                                    image={article.image}
                                    alt={article.alt}
                                    className={articlesStyles.resourceElementPictureMobile}
                                />
                                <div className={articlesStyles.resourceElementTextContainerMobile}>
                                    <div className={articlesStyles.resourceElementTextMobile}>
                                        <h3 className={articlesStyles.resourceElementTitleMobileExternal} dangerouslySetInnerHTML={{ __html: article.type }} />
                                        <h3 className={articlesStyles.resourceElementTitleMobileExt} dangerouslySetInnerHTML={{ __html: article.name }} />
                                        <p className={articlesStyles.resourceElementCategoryMobileExternal} dangerouslySetInnerHTML={{ __html: article.date }} />
                                        <p className={articlesStyles.itemLearnMobileExternal}>
                                            {t('siteMetadata.index.readMore')}
                                            <FontAwesomeIcon
                                                icon={faArrowUpRightFromSquare}
                                                className={homeStyles.externalLink}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </a>
                    ))}
                {articlesToDisplay.length > count ? (
                    <button
                        onClick={loadMore}
                        className={articlesStyles.blueButton}
                        type='button'
                    >
                        {'More articles'}
                    </button>
                )
                    : null}
            </section>
        </>
    );
}
