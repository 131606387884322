import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@witbe/ui-react-components/dist/Pagination';

import { useViewport } from '../../hooks';

import BlogArticleCard from '../blog/BlogArticleCard';

import articlesStyles from '../../pages/styles/articles.module.scss';
import styles from './styles/resourcesArticles.module.scss';

const propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageNum: PropTypes.number.isRequired,
};

const FilterShowAll = ({ data, pageNum }) => {
    const { isTabletBreakpoint } = useViewport();

    const [ page, setPage ] = useState(1)

    const articlesToDisplay = data;

    const pages = Math.ceil(articlesToDisplay.length / 10);
    const [ articles, setArticles ] = useState([]);
    const articlesPerPage = 10;
    const articlesViewed = (page * articlesPerPage) - articlesPerPage;

    const onPageChange = useCallback((e) => {
        const pageNumber = e.value
        setPage(pageNumber);
        if (window) {
            const offsetY = 700;
            window.scrollTo(0, offsetY);
        }
    }, []);

    useEffect(() => {
        let articlesForThisPage = [];
        articlesForThisPage = articlesToDisplay.slice(articlesViewed, articlesViewed + articlesPerPage)
        setArticles(articlesForThisPage)
    }, [page, pageNum]);

    return (
        <React.Fragment>
            {isTabletBreakpoint && <div className={articlesStyles.featured}>Featured</div>}
            <section className={styles.articlesContainer}>
                {articles.map(article => <BlogArticleCard article={article} key={article.postId} />)}
            </section>
            <section className={styles.paginationContainer}>
                <div className={styles.pagination}>
                    <Pagination
                        count={pages}
                        page={pageNum}
                        hidePagesList
                        hidePrevButton={ page === 1 ? true : false }
                        hideNextButton={ page === pages ? true : false }
                        onChange={onPageChange}
                    />
                </div>
            </section>
        </React.Fragment>
    );
}

FilterShowAll.propTypes = propTypes;

export default FilterShowAll;
