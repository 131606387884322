/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '@witbe/ui-react-components/dist/Pagination';
import BlogArticleCard from '../blog/BlogArticleCard';
import styles from './styles/resourcesArticles.module.scss';
import articlesStyles from '../../pages/styles/articles.module.scss';
import { useViewport } from '../../hooks';

export default function SearchList({ filteredArticles, pageNum }) {

    const [page, setPage] = useState(1);

    const { isTabletBreakpoint } = useViewport();

    const pages = Math.ceil((removeDuplicates(filteredArticles)).length / 10);
    const [articles, setArticles] = useState([]);
    const articlesPerPage = 10;
    const articlesViewed = page * articlesPerPage - articlesPerPage;

    const onPageChange = useCallback((e) => {
        let pageNumber = e.value
        setPage(pageNumber);
        if (window) {
            let offsetY = 700;
            window.scrollTo(0, offsetY);
        }
    }, []);

    function removeDuplicates(data) {
        return [...new Set(data)]
    }

    useEffect(() => {
        let articlesForThisPage = [];
        articlesForThisPage = (removeDuplicates(filteredArticles)).slice(articlesViewed, articlesViewed + articlesPerPage)
        setArticles(articlesForThisPage)
    }, [page, filteredArticles]);

    const titleStyle = articles.length === 0 ? articlesStyles.noshow : articlesStyles.show;

    const container = articles.length === 0 ? styles.articlesContainer : styles.articlesContainerMarginSearch;

    return (
        <>
            <div>
                {
                    isTabletBreakpoint
                    && <div className={titleStyle}>Search results</div>
                }
                <section className={container}>
                    {articles.map((article) => <BlogArticleCard article={article} key={article.postId} />)}
                </section>
            </div>
            <section className={styles.paginationContainer}>
                <div className={styles.pagination}>
                    {
                        pages > 1 &&
                        <Pagination
                            count={pages}
                            page={pageNum}
                            hidePagesList
                            hidePrevButton={ page === 1 ? true : false }
                            hideNextButton={ page === pages ? true : false }
                            onChange={onPageChange}
                        />
                    }
                </div>
            </section>
        </>
    );
}
