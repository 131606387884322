import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BlogArticleCard from '../blog/BlogArticleCard';

import styles from './styles/resourcesArticles.module.scss';
import articlesStyles from '../../pages/styles/articles.module.scss';

const propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const FilterShowAllMobile = ({ data }) => {
    const [ count, setCount ] = useState(10);

    const loadMore = () => {
        setCount(count + 5);
    }

    const articlesToDisplay = data.slice(0, count);

    return (
        <React.Fragment>
            <div className={articlesStyles.featured}>
                <h2>Featured</h2>
            </div>
            <section className={styles.articlesContainer}>
                {articlesToDisplay.map(article => <BlogArticleCard article={article} key={article.postId} />)}
                {
                    articlesToDisplay.length > count
                    && <button type='button' onClick={loadMore} className={articlesStyles.blueButton}>More articles</button>
                }
            </section>
        </React.Fragment>
    );
}

FilterShowAllMobile.propTypes = propTypes;

export default FilterShowAllMobile;
