/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import mobileStyles from './styles/mobileStyles.module.scss';
import { topics } from '../../utils/topics';
import { industries } from '../../utils/industries';
import dropdownStyles from './styles/dropdown.module.scss';

export default function Dropdown({ showModal, onClose }) {
    const [checkedState, setCheckedState] = useState(
        new Array(topics.length).fill(false)
    );

    const [checkedStateIndustry, setCheckedStateIndustry] = useState(
        new Array(industries.length).fill(false)
    );

    //Filtered by Topic and Industry
    const [total, setTotal] = useState([]);
    const [totalIndustry, setTotalIndustry] = useState([]);

    //Filtered by Topic
    const handleOnChange = position => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        const checkedTopics = [];
        updatedCheckedState.forEach((item, index) => {
            if (item) {
                checkedTopics.push(topics[index].name);
            }
        });
        setTotal(checkedTopics);
    };

    //Filtered by Industry
    const handleOnChangeIndustry = position => {
        const actualPosition = position - 4;

        const updatedCheckedStateIndustry = checkedStateIndustry.map((item, index) =>
            index === actualPosition ? !item : item
        );
        setCheckedStateIndustry(updatedCheckedStateIndustry);

        const checkedIndustries = [];
        updatedCheckedStateIndustry.forEach((item, index) => {
            if (item) {
                checkedIndustries.push(industries[index].name);
            }
        });
        setTotalIndustry(checkedIndustries);
    };

    //Clear all filters
    function handleClearAll() {
        const updatedCheckedState = checkedState.map(item => false);
        setCheckedState(updatedCheckedState);
        const updatedCheckedStateIndustry = checkedStateIndustry.map(item => false);
        setCheckedStateIndustry(updatedCheckedStateIndustry);
        setTotal([]);
        setTotalIndustry([]);
    }

    const topicTotal = total;
    const industryTotal = totalIndustry;

    if (!showModal) {
        return null;
    }

    return (
        <div className={dropdownStyles.modalEnter}>
            <div className={dropdownStyles.modalHeader}>
                <div className={mobileStyles.head}>
                    Filter articles
                </div>
                <div onClick={() => onClose([])} className={mobileStyles.resetButton}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>

            <div className={dropdownStyles.contentWrapper}>
                <div className={dropdownStyles.modalBody}>
                    <h3 className={dropdownStyles.dropdownHeader}>Topic</h3>
                    <ul className={dropdownStyles.dropdownListTopic}>
                        {topics.map(topic => (
                            <li key={topic.id}>
                                <input
                                    onClick={() => handleOnChange(topic.id)}
                                    className={dropdownStyles.listItem}
                                    type='checkbox'
                                    id={`custom-checkbox-${topic.id}`}
                                    name={topic}
                                    value={topic}
                                    checked={checkedState[topic.id]}
                                />
                                <label htmlFor={`custom-checkbox-${topic.id}`}>
                                    {topic.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                    <h3 className={dropdownStyles.dropdownHeader}>Industry</h3>
                    <ul className={dropdownStyles.dropdownListTopic}>
                        {industries.map(industry => (
                            <li key={industry.id}>
                                <input
                                    onClick={() => handleOnChangeIndustry(industry.id)}
                                    className={dropdownStyles.listItem}
                                    type='checkbox'
                                    id={`custom-checkbox-${industry.id}`}
                                    name={industry}
                                    value={industry}
                                    checked={checkedStateIndustry[industry.id - 4]}
                                />
                                <label htmlFor={`custom-checkbox-${industry.id}`} >
                                    {industry.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className={dropdownStyles.modalFooter}>
                <div className={mobileStyles.resetLeft} onClick={handleClearAll}>Reset</div>
                <div className={mobileStyles.applyRight} onClick={() => onClose(topicTotal, industryTotal)}>Apply</div>
            </div>
        </div>
    )

}
