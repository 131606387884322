/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';

import Pagination from '@witbe/ui-react-components/dist/Pagination';

import { useViewport } from '../../hooks';

import BlogArticleCard from '../blog/BlogArticleCard';

import articlesStyles from '../../pages/styles/articles.module.scss';
import styles from './styles/resourcesArticles.module.scss';


export default function FilteredResources({ search, searchState, topics, industries, data, pageNum }) {
    const [page, setPage] = useState(1);

    const { isTabletBreakpoint } = useViewport();

    const articlesToDisplay = data.filter(article =>
        topics.some(item => article.topics?.includes(item)) || industries.some(item => article.industries?.includes(item))
    );

    const allFilters = [...topics, ...industries];

    const pages = Math.ceil((removeDuplicates(articlesToDisplay)).length / 10);
    const [articles, setArticles] = useState([]);
    const articlesPerPage = 10;
    const articlesViewed = page * articlesPerPage - articlesPerPage;

    const onPageChange = useCallback((e) => {
        let pageNumber = e.value
        setPage(pageNumber);
        if (window) {
            let offsetY = 700;
            window.scrollTo(0, offsetY);
        }
    }, []);

    function removeDuplicates(data) {
        return [...new Set(data)]
    }

    useEffect(() => {
        let articlesForThisPage = [];
        articlesForThisPage = (removeDuplicates(articlesToDisplay)).slice(articlesViewed, articlesViewed + articlesPerPage)
        // let articlesForThisPageSearch = [];
        // articlesForThisPageSearch = (removeDuplicates(filteredArticles)).slice(articlesViewed, articlesViewed + articlesPerPage)
        setArticles(articlesForThisPage)
    }, [page, search, topics, industries]);

    useEffect(() => {
        setPage(1);
    }, [topics, industries]);

    const container = allFilters.length === 0 ? styles.articlesContainer : styles.articlesContainerMargin

    const titleStyle = allFilters.length === 0 ? articlesStyles.noshow : articlesStyles.show;

    return (
        <>
            {isTabletBreakpoint && <div className={titleStyle}>Filtered</div>}
            <section className={container}>
                {articles.length !== 0 && articles.map(article => <BlogArticleCard article={article} key={article.postId} />)}
            </section>
            <section className={styles.paginationContainer}>
                <div className={styles.pagination}>
                    {pages > 1 ?
                        <Pagination
                        count={pages}
                        page={page}
                        hidePagesList
                        hidePrevButton={ page === 1 ? true : false }
                        hideNextButton={ page === pages ? true : false }
                        onChange={onPageChange}
                        />
                        :null
                    }
                </div>
            </section>
        </>
    );
}
