import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

import { useViewport } from '../../hooks';
import { joinItemsWithBullet } from '../../utils';

import Link from '../common/Link';

import cssResources from '../../pages/styles/resources.module.scss';
import articlesStyles from '../../pages/styles/articles.module.scss';

const BlogArticleCard = ({ article }) => {
    const { isMobileBreakpoint } = useViewport();

    return (
        <Link
            to={article.link}
            className={isMobileBreakpoint ? articlesStyles.resourceElementMobile : cssResources.resourceElement}
        >
            <GatsbyImage
                image={isMobileBreakpoint ? article.mobileImage : article.image}
                alt={article.thumbnailAlt}
                className={isMobileBreakpoint ? articlesStyles.resourceElementPictureMobile : cssResources.resourceElementPicture}
            />
            <div className={isMobileBreakpoint ? articlesStyles.resourceElementTextContainerMobile : cssResources.resourceElementTextContainer}>
                <div className={isMobileBreakpoint ? articlesStyles.resourceElementTextMobile : cssResources.resourceElementText}>
                    <h3
                        className={isMobileBreakpoint ? articlesStyles.resourceElementTitleMobile : cssResources.resourceElementTitle}
                        dangerouslySetInnerHTML={{__html: article.titleList}}
                    />
                    <p className={isMobileBreakpoint ? articlesStyles.resourceElementCategoryMobile : cssResources.resourceElementCategory}>
                        {joinItemsWithBullet(article.topics)}
                    </p>
                    <p
                        className={isMobileBreakpoint ? articlesStyles.resourceElementSubtitleMobile : cssResources.resourceElementSubtitle}
                        dangerouslySetInnerHTML={{__html: article.subtitleList}}
                    />
                    <p className={isMobileBreakpoint ? articlesStyles.itemLearnMobile : cssResources.resourceElementRead}>
                        Read more
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className={articlesStyles.chevronRightBlue}
                        />
                    </p>
                </div>
            </div>
        </Link>
    );
}

export default BlogArticleCard;
