/* eslint-disable react/prop-types */
/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Layout } from '../components/common';
import SEO from '../components/common/SEO';
import { useViewport } from '../hooks';
import MobileResources from '../components/common/MobileResources';
import DesktopResources from '../components/common/DesktopResources';

export default function Articles({ location }) {
    const { width } = useViewport();
    const breakpoint = 500;

    const topicData = location.state ? location.state.topic : null;
    const industryData = location.state ? location.state.industries : null;
    const searchProp = location.state ? location.state.searchProp : null;

    return (

        <Layout title="Articles - Witbe">
            <SEO
                title="Articles - Witbe"
                ogDescription="Learn about relevant industry topics. You'll also learn the importance of Quality of Experience and how you can automate tests and proactively monitor services"
                description="Learn about relevant industry topics. You'll also learn the importance of Quality of Experience and how you can automate tests and proactively monitor services"
                article="article"
            />

            {width < breakpoint ? (<MobileResources topicData={topicData} industryData={industryData} searchProp={searchProp} />) : (<DesktopResources topicData={topicData} industryData={industryData} searchProp={searchProp} />)}
        </Layout>
    )
}
