/* eslint-disable func-style */
/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect, useContext } from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Modal from '@witbe/ui-react-components/dist/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { useTranslation } from 'react-i18next';
import { stripHtmlTags } from '../../utils';
import { topics } from '../../utils/topics';
import { industries } from '../../utils/industries';
import cssResources from '../../pages/styles/resources.module.scss';
import SearchList from './SearchList';
import SearchListNoResults from './SearchListNoResults';
import productStyles from '../../pages/styles/newProducts.module.scss';
import techStyles from '../../pages/styles/newTechnology.module.scss';
import articlesStyles from '../../pages/styles/articles.module.scss';
import mobileStyles from './styles/mobileStyles.module.scss';
import homeStyles from '../../pages/styles/newHome.module.scss';
import styles from './styles/resourcesArticles.module.scss';
import { DropdownModalContext, DropdownIndustryModalContext } from '../../context';
import { useArticles, useExternalArticles, useViewport } from '../../hooks';
import Dropdown from './Dropdown';
import FilteredResources from './FilteredResources';
import FilterShowAll from './FilterShowAll';
import ogimage from '../../images/blog/2023/nab/product-of-the-year-award-og-image.jpg';
import dropdownStyles from './styles/dropdown.module.scss';
import BlogSidebarPosts from '../blog/BlogSidebarPosts';
import SubscribeBlueBoxModal from './SubscribeBlueBoxModal';
import ExternalArticlesDesktop from './ExternalArticlesDesktop';

export default function DesktopResources({ topicData, industryData, searchProp }) {
    const { t } = useTranslation();
    const { width, isTabletBreakpoint } = useViewport();
    const articleData = useExternalArticles();
    const articleDataArray = useArticles();

    const [ filteredData, setFilteredData ] = useState([]);
    const [ filteredIndustryData, setFilteredIndustryData ] = useState([]);
    //Dropdown Modal - TOPIC
    const [ toggleDropdownModal, setToggleDropdownModal ] = useContext(DropdownModalContext);
    const [ dropdownSubmitted, setDropdownSubmitted ] = useState(false);
    //Dropdown Modal - INDUSTRY
    const [ toggleDropdownIndustryModal, setToggleDropdownIndustryModal ] = useContext(DropdownIndustryModalContext);
    const [ dropdownIndustrySubmitted, setDropdownIndustrySubmitted ] = useState(false);
    const [ filterHeaderIsOpen, setFilterHeaderIsOpen ] = useState(false);
    const [ checkedState, setCheckedState ] = useState(Array.from({ length: topics.length }).fill(false));
    const [ checkedStateIndustry, setCheckedStateIndustry ] = useState(Array.from({ length: industries.length }).fill(false));
    //Search bar
    const [ searchField, setSearchField ] = useState('');
    const [ searchShow, setSearchShow ] = useState(false);
    //Filtered by Topic and Industry
    const [ total, setTotal ] = useState([]);
    const [ totalIndustry, setTotalIndustry ] = useState([]);
    const [ searchArticles, setSearchArticles ] = useState(articleDataArray);

    const breakpoint = 500;
    const breakpointTablets = 821;

    const myArray = Array.from({ length: topics.length }).fill(false);
    const currentTopic = topics.find(element => element.name === topicData);
    const slicedArray = topicData ? myArray.toSpliced(currentTopic.id, 1, true) : null;

    const myArrayIndustry = Array.from({ length: industries.length }).fill(false);
    const currentIndustry = industries.find(element => element.name === industryData);
    const slicedArrayIndustry = industryData ? myArrayIndustry.toSpliced((currentIndustry.id - 4), 1, true) : null;

    const filterOpen = filterHeaderIsOpen ? styles.filterIsOpen : '';
    // Featured article will be displayed at the top of the desktop page
    const featuredArticle = articleDataArray.find(article => article.featuredOnBlog);

    const myDropdownToggle = (data, industryData) => {
        setToggleDropdownModal(!toggleDropdownModal);
        setDropdownSubmitted(false);
        setFilteredIndustryData([]);

        data ? setFilteredData(data)
            : null;

        industryData ? setFilteredIndustryData(industryData)
            : null;
    };

    const myDropdownIndustryToggle = (childdata) => {
        setToggleDropdownIndustryModal(!toggleDropdownIndustryModal);
        setDropdownIndustrySubmitted(false);
        setFilteredData([]);

        childdata ? setFilteredIndustryData(childdata)
            : null;
    };

    const filteredArticles = articleDataArray.filter(
        // eslint-disable-next-line arrow-body-style
        article => {
            const search = searchField.toLowerCase();
            return (
                stripHtmlTags(article.titleList)
                    .toLowerCase()
                    .includes(search) ||
                article
                    .subtitleList
                    ?.toLowerCase()
                    .includes(search) ||
                article
                    .keywordsSearch
                    ?.toLowerCase()
                    .includes(search) ||
                article
                    .topics
                    ?.map(word => word.toLowerCase())
                    ?.toString()
                    .includes(search) ||
                article
                    .industries
                    ?.map(word => word.toLowerCase())
                    ?.toString()
                    .includes(search) ||
                article
                    .tags
                    ?.map(word => word.toLowerCase())
                    ?.toString()
                    .includes(search)
            );
        });

    //Filtered by Topic
    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);

        const checkedTopics = [];

        const totalTopics = updatedCheckedState.map(
            (item, index) => {
                if (item) {
                    checkedTopics.push(topics[index].name);
                }
                return checkedTopics;
            },
        );

        setTotal(checkedTopics);
    };

    //Filtered by Industry
    const handleOnChangeIndustry = (position) => {
        const actualPosition = position - 4;

        console.log('position: ' + position);

        const updatedCheckedStateIndustry = checkedStateIndustry.map((item, index) =>
            index === actualPosition ? !item : item
        );

        setCheckedStateIndustry(updatedCheckedStateIndustry);

        const checkedIndustries = [];

        const totalIndustries = updatedCheckedStateIndustry.map(
            (item, index) => {
                console.log('item: ' + item);
                if (item) {
                    console.log('index: ' + item);
                    //   checkedIndustries.push(industries.find(el => el.id === position).name);
                    checkedIndustries.push(industries[index - 1].name);
                }
                return checkedIndustries;
            },
        );

        setTotalIndustry(checkedIndustries);
        console.log('industries array: ' + checkedStateIndustry);
    };

    function handleOnChangeSearch() {
        setSearchField('');
        setSearchShow(false);
        setSearchArticles(articleDataArray);
    };

    //Clear all filters
    function handleClearAll() {
        setCheckedState(new Array(topics.length).fill(false));
        setCheckedStateIndustry(new Array(industries.length).fill(false));
        setTotal([]);
        setTotalIndustry([]);
        setSearchField('');
        setSearchShow(false);
    }

    //Search bar
    const handleChange = e => {
        setSearchField(e.target.value);
        if (e.target.value === '') {
            setSearchShow(false);
        }
        else {
            setSearchShow(true);
            if (window && width > breakpointTablets) {
                // window.scrollTo(0, offsetY);
                window.scrollTo({
                    top: 700,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    };

    //Search button
    const handleClick = e => {
        setSearchField(e.currentTarget.value);
        if (e.currentTarget.value === '') {
            setSearchShow(false);
        } else {
            setSearchShow(true);
        }
    };

    //Search function
    function searchList() {
        if (searchShow) {
            if (filteredArticles.length > 0) {
                return (
                    <SearchList filteredArticles={filteredArticles} pageNum={page}/>
                );
            }
            return (
                <SearchListNoResults />
            );
        }
    };

    const allFilters = [ ...total, ...totalIndustry ];

    const [ page, setPage ] = useState(1);
    const pages = Math.ceil(articleDataArray.length / 10);
    const [ articles, setArticles ] = useState([]);
    const articlesPerPage = 10;
    const articlesViewed = page * articlesPerPage - articlesPerPage;

    useEffect(() => {
        searchShow ? setSearchArticles(filteredArticles)
            : setSearchArticles(articleDataArray);
    }, [ searchField, total, totalIndustry ]);

    useEffect(() => {
        setPage(1);
    }, [ searchField, total, totalIndustry ]);

    useEffect(() => {
        topicData ? setCheckedState(slicedArray) : null;
    }, []);

    useEffect(() => {
        topicData && window ? window.scrollTo({top: 900, left: 0, behavior: 'smooth'}) : null;
    }, [ topicData ]);

    useEffect(() => {
        topicData ? setTotal([ topicData ]) : null;
    }, []);

    useEffect(() => {
        industryData ? setCheckedStateIndustry(slicedArrayIndustry) : null;
    }, []);

    useEffect(() => {
        industryData && window ? window.scrollTo({top: 900, left: 0, behavior: 'smooth'}) : null;
    }, [ industryData ]);

    useEffect(() => {
        industryData ? setTotalIndustry([ industryData ]) : null;
    }, []);

    useEffect(() => {
        searchProp ? setSearchShow(true) : null;
    }, []);

    useEffect(() => {
        searchProp && window ? window.scrollTo({top: 900, left: 0, behavior: 'smooth'}) : null;
    }, [ searchProp ]);

    useEffect(() => {
        searchProp ? setSearchField(searchProp) : null;
    }, []);

    return (
        <>
            <h1 className={articlesStyles.hidden}>Articles</h1>
            {isTabletBreakpoint ? (
                <>
                    <Modal
                        visible={toggleDropdownModal}
                        onClose={myDropdownToggle}
                        style={{ position: `fixed` }}
                        className={dropdownStyles.modalContainer}
                    >
                        {
                            dropdownSubmitted === false &&
                                <Dropdown
                                    showModal={true}
                                    onClose={myDropdownToggle}
                                    setSubmitted={setDropdownSubmitted}
                                />
                        }
                    </Modal>
                    <Modal
                        visible={toggleDropdownIndustryModal}
                        onClose={myDropdownIndustryToggle}
                    >
                        {dropdownIndustrySubmitted === false ?
                            <Dropdown
                                showModal={true}
                                onClose={myDropdownIndustryToggle}
                                setSubmitted={setDropdownIndustrySubmitted}
                            />
                            :
                            null
                        }
                    </Modal>
                    <section id={articlesStyles.searchSectionMobile}>
                        <section id={articlesStyles.searchBar}>
                            <div id={cssResources.subscribeLabel}>
                                <div className={cssResources.subscribeInput}>
                                    {/* <FontAwesomeIcon icon={faSearch} className={cssResources.searchIcon} /> */}
                                    <input
                                        className={cssResources.subscribeInputField}
                                        placeholder={"Search articles"}
                                        type="search"
                                        value={searchField}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </section>
                        <section id={articlesStyles.dropdown}>
                            <div className={mobileStyles.dropdownContainer}>
                                <div className={mobileStyles.dropdownHeaderLeft} onClick={setToggleDropdownModal}>
                                    <p>Filters</p>
                                </div>
                            </div>
                        </section>
                    </section>
                </>
            ) : (
                <>
                    <div id={techStyles.mainImageWrapper}>
                        <div className={techStyles.mainImageTextWrapper}>
                            <GatsbyImage image={featuredArticle.bannerImage} alt='Main image' className={homeStyles.gatsbyImage}/>
                            <div className={articlesStyles.mainImageContentGradient}>
                                <h1 className={articlesStyles.mainImageTitleGradient} dangerouslySetInnerHTML={{__html: featuredArticle.titleList}}></h1>
                                <p className={articlesStyles.mainImageSubGradient} dangerouslySetInnerHTML={{__html: featuredArticle.subtitleList}}></p>
                                <div className={productStyles.learnMoreBuyButtons}>
                                    <Link
                                        to={featuredArticle.link}
                                        className={homeStyles.transparentButton + ' ' + homeStyles.buyButton}
                                    >
                                        {t('siteMetadata.articles.readMore')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section id={articlesStyles.searchSection}>
                        <section id={articlesStyles.searchBar}>
                            <button
                                value={searchField}
                                onClick={handleClick}
                                className={cssResources.searchButton}
                                label={t(`siteMetadata.resources.searchButton`)}
                                size='medium'
                                style={{ fontSize: 'var(--fontSize-default)' }}
                            >
                                Search
                            </button>
                            <div id={cssResources.subscribeLabel}>
                                <div className={cssResources.subscribeInput}>
                                    <FontAwesomeIcon icon={faSearch} className={cssResources.searchIcon} />
                                    <input
                                        className={cssResources.subscribeInputField}
                                        placeholder={width < breakpoint ? "Search" : "Search articles"}
                                        type="search"
                                        value={searchField}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </section>
                    </section>
                </>
            )}
            {isTabletBreakpoint ?
                (
                    <>
                        <section id={cssResources.articlesContainer}>
                            <div className={cssResources.articlesMain}>
                                {
                                    searchShow && filteredData.length === 0 && filteredIndustryData.length === 0 ?
                                        searchList()
                                        :null
                                }
                                {
                                    (filteredData !== undefined || filteredData.length > 0) || (filteredIndustryData !== undefined || filteredIndustryData.length > 0) && !searchShow ?
                                        <FilteredResources search={searchField} searchState={searchShow} topics={filteredData} industries={filteredIndustryData} data={searchArticles} pageNum={page}/>
                                        :null
                                }
                                {
                                    filteredData.length === 0 && filteredIndustryData.length === 0 && !searchShow ?
                                        <FilterShowAll data={articleDataArray} pageNum={page}/>
                                        :null
                                }
                            </div>
                        </section>
                        <ExternalArticlesDesktop data={articleData} />
                    </>
                )
                :
                (
                    <>
                        <section id={articlesStyles.articlesContainer}>
                            <aside className={cssResources.sidebar}>
                                <div className={cssResources.listsContainer}>
                                    <ul className={cssResources.ul}>
                                        <h3 className={cssResources.sidebarHeader}>Topic</h3>
                                        {topics.map((topic) => {
                                            return (
                                                <li key={topic.id}>
                                                    <div className={cssResources.checkedItem}>
                                                        <input
                                                            className={cssResources.customCheckbox}
                                                            type="checkbox"
                                                            id={`custom-checkbox-${topic.id}`}
                                                            name={topic}
                                                            value={topic}
                                                            defaultChecked={checkedState[topic.id]}
                                                            onChange={() => handleOnChange(topic.id)}
                                                        />
                                                        <label
                                                            htmlFor={`custom-checkbox-${topic.id}`}
                                                        >
                                                            {topic.name}
                                                        </label>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <ul className={cssResources.ul}>
                                        <h3 className={cssResources.sidebarHeader}>Industry</h3>
                                        {industries.map((industry) => {
                                            return (
                                                <li key={industry.id}>
                                                    <div className={cssResources.checkedItem}>
                                                        <input
                                                            className={cssResources.customCheckbox}
                                                            type="checkbox"
                                                            id={`custom-checkbox-${industry.id}`}
                                                            name={industry}
                                                            value={industry}
                                                            defaultChecked={checkedStateIndustry[industry.id - 4]}
                                                            onChange={() => handleOnChangeIndustry(industry.id)}
                                                        />
                                                        <label
                                                            htmlFor={`custom-checkbox-${industry.id}`}
                                                        >
                                                            {industry.name}
                                                        </label>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <BlogSidebarPosts isBlogHome />
                                    <SubscribeBlueBoxModal />
                                </div>
                            </aside>
                            <div className={cssResources.articlesMain + filterOpen}>
                                <section className={styles.filtersHeader}>
                                    <ul className={styles.filtersList}>
                                        {topics.map((topic) => {
                                            return (
                                                <li key={topic.id} className={styles.filtersItem}>
                                                    {/* <div className={styles.filtersItem}> */}
                                                    <input
                                                        onClick={() => handleOnChange(topic.id)}
                                                        className={styles.filtersItem}
                                                        // label={`custom-checkbox-${topic.id}`}
                                                        type="checkbox"
                                                        id={`custom-checkbox-${topic.id}`}
                                                        name={topic}
                                                        value={topic}
                                                        defaultChecked={checkedState[topic.id]}
                                                    />
                                                    <label
                                                        htmlFor={`custom-checkbox-${topic.id}`}
                                                    >
                                                        <p>{topic.name}</p>
                                                        <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
                                                    </label>
                                                    {/* </div> */}
                                                </li>
                                            );
                                        })}
                                        {industries.map((industry) => {
                                            return (
                                                <li key={industry.id} className={styles.filtersItem}>
                                                    {/* <div className={styles.filtersItem}> */}
                                                    <input
                                                        onClick={() => handleOnChangeIndustry(industry.id)}
                                                        className={styles.filtersItem}
                                                        // label={`custom-checkbox-${topic.id}`}
                                                        type="checkbox"
                                                        id={`custom-checkbox-${industry.id}`}
                                                        name={industry}
                                                        value={industry}
                                                        defaultChecked={checkedStateIndustry[industry.id - 4]}
                                                    />
                                                    <label
                                                        htmlFor={`custom-checkbox-${industry.id}`}
                                                    >
                                                        <p>{industry.name}</p>
                                                        <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
                                                    </label>
                                                    {/* </div> */}
                                                </li>
                                            );
                                        })}
                                        {
                                            searchShow ?
                                                <li className={styles.filtersItem}>
                                                    <button
                                                        onClick={handleOnChangeSearch}
                                                        className={styles.searchItem}
                                                    >
                                                        <p>{searchField}</p>
                                                        <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
                                                    </button>
                                                </li>
                                                :null
                                        }
                                        {
                                            allFilters.length > 0  || searchShow ?
                                                <li className={styles.filtersItem}>
                                                    <button
                                                        onClick={handleClearAll}
                                                        className={cssResources.clearButton}
                                                        style={{ fontSize: 'var(--fontSize-default)' }}
                                                    >
                                                            Clear all
                                                    </button>
                                                </li>
                                                :null
                                        }


                                    </ul>
                                </section>

                                {
                                    searchShow && total.length === 0 && totalIndustry.length === 0 ?
                                        searchList()
                                        :null
                                }

                                {
                                    (total !== undefined || total.length > 0) || (totalIndustry !== undefined || totalIndustry.length > 0) ?
                                        <FilteredResources search={searchField} searchState={searchShow} topics={total} industries={totalIndustry} data={searchArticles} pageNum={page}/>
                                        :null
                                }

                                {
                                    totalIndustry.length === 0 && total.length === 0 && !searchShow ?
                                        <FilterShowAll data={articleDataArray} pageNum={page}/>
                                        :null
                                }
                            </div>
                        </section>
                        <ExternalArticlesDesktop data={articleData} />
                    </>
                )
            }
        </>
    );
}
