/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';

import { useViewport } from '../../hooks';

import BlogArticleCard from '../blog/BlogArticleCard';

import styles from './styles/resourcesArticles.module.scss';
import articlesStyles from '../../pages/styles/articles.module.scss';

export default function SearchListMobile({ filteredArticles, pageNum }) {
    const { width } = useViewport();
    const breakpointTablets = 821;

    const [count, setCount] = useState(10);

    const loadMore = () => {
        setCount(count + 10);
    }

    const articlesToDisplay = removeDuplicates(filteredArticles);

    function removeDuplicates(data) {
        return [...new Set(data)]
    }

    const titleStyle = articlesToDisplay.length === 0 ? articlesStyles.noshow : articlesStyles.show;

    const container = articlesToDisplay.length === 0 ? styles.articlesContainer : styles.articlesContainerMarginSearch;

    return (
        <>
            <div>
                {width < breakpointTablets && <div className={titleStyle}>Search results</div>}
                <section className={container}>
                    {articlesToDisplay.slice(0, count).map((article) => <BlogArticleCard article={article} />)}

                    {articlesToDisplay.length > count &&
                        <button
                            onClick={loadMore}
                            className={articlesStyles.blueButton}
                        >
                            More articles
                        </button>
                    }
                </section>
            </div>
        </>
    );
}
