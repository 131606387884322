import React from 'react';
import { useTranslation } from 'react-i18next';

import { useArticles } from '../../hooks';

import BlogArticleCard from '../blog/BlogArticleCard';

import styles from './styles/resourcesArticles.module.scss';
import articlesStyles from '../../pages/styles/articles.module.scss';

const SearchListNoResults = () => {
    const { t } = useTranslation();

    const articleData = useArticles();
    const recentArticles = articleData.slice(0, 3);

    return (
        <div>
            <div className={articlesStyles.showNoResults}>
                <h1>{t('siteMetadata.articles.noResults')}</h1>
                <p>{t('siteMetadata.articles.otherArticles')}</p>
            </div>
            <section className={styles.articlesContainerMargin}>
                <div className={styles.articlesContainer}>
                    {recentArticles.map(article => <BlogArticleCard article={article} key={article.postId} />)}
                </div>
            </section>
        </div>
    )
}

export default SearchListNoResults;
