import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '../../context';
import { GTM_KEYS, MODAL_TYPES, MODAL_SOURCE_BUTTONS } from '../../constants';

import styles from './styles/subscribeForm.module.scss';

const { NEWSLETTER } = MODAL_TYPES;
const { BLUE_BOX } = MODAL_SOURCE_BUTTONS;
const { MODAL: { NEWSLETTER_BUTTON } } = GTM_KEYS;

const SubscribeBlueBoxModal = () => {
    const { t } = useTranslation();

    const { openModal } = useContext(ModalContext);
    const openNewsletterModal = useCallback(
        () => openModal({ modalType: NEWSLETTER, sourceButton: BLUE_BOX }),
        [ openModal ],
    );

    return (
        <div className={styles.newsContainer}>
            <h3 className={styles.newsHeader}>Stay updated on news from Witbe</h3>
            <p className={styles.subscribeWarning}>
                {t('siteMetadata.footer.subscribe.subtitle2')}
            </p>
            <div className={styles.witboxButtons}>
                <div
                    className={`${styles.whiteButton} ${styles.buyButton}`}
                    onClick={openNewsletterModal}
                    data-gtm-id={`${NEWSLETTER_BUTTON}-${BLUE_BOX}`}
                >
                    {t('siteMetadata.footer.subscribe.title5')}
                </div>
            </div>
        </div>
    );
}

export default SubscribeBlueBoxModal;
