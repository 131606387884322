/* eslint-disable func-style */
/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-danger */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';
import { useTranslation } from 'react-i18next';
import homeStyles from '../../pages/styles/newHome.module.scss';

export default function ExternalArticlesDesktop({ data }) {
    const { t } = useTranslation();

    const articlesToDisplay = data;

    return (
        <section id={homeStyles.quadColSectionNoPargin}>
            <h2 id={homeStyles.quadColTitle}>
                {t('siteMetadata.index.witbeInTheNews')}
            </h2>
            <ul id={homeStyles.quadColList}>
                {articlesToDisplay.slice(0, 4)
                    .map(article => (
                        <a href={article.link} target='_blank' alt={article.alt} key={article.postId}>
                            <li className={homeStyles.item}>
                                <div className={homeStyles.itemThumb}>
                                    <GatsbyImage
                                        image={article.image}
                                        alt={article.alt}
                                        className={homeStyles.itemIcon}
                                    />
                                </div>
                                <div className={homeStyles.itemText}>
                                    <h2 className={homeStyles.itemType} dangerouslySetInnerHTML={{ __html: article.type }} />
                                    <h3 className={homeStyles.itemTitleExt} dangerouslySetInnerHTML={{ __html: article.name }} />
                                    <p className={homeStyles.itemDate} dangerouslySetInnerHTML={{ __html: article.date }} />
                                    <p className={homeStyles.itemLearnMore}>
                                        {t('siteMetadata.index.readMore')}
                                        <FontAwesomeIcon
                                            icon={faArrowUpRightFromSquare}
                                            className={homeStyles.externalLink}
                                        />
                                    </p>
                                </div>
                            </li>
                        </a>
                    ))}
            </ul>
        </section>
    );
}
